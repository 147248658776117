const _temp0 = require("url:./button-click-1.m4a");
const _temp1 = require("url:./button-hover-1.m4a");
const _temp2 = require("url:./chime-1.m4a");
const _temp3 = require("url:./egbert-choir.m4a");
const _temp4 = require("url:./page-turn-1.m4a");
const _temp5 = require("url:./short-chime-1.m4a");
const _temp6 = require("url:./short-chime-2.m4a");
const _temp7 = require("url:./short-chime-3.m4a");
const _temp8 = require("url:./short-chime-4.m4a");
const _temp9 = require("url:./short-chime-5.m4a");
module.exports = {
  "button-click-1": _temp0,
  "button-hover-1": _temp1,
  "chime-1": _temp2,
  "egbert-choir": _temp3,
  "page-turn-1": _temp4,
  "short-chime-1": _temp5,
  "short-chime-2": _temp6,
  "short-chime-3": _temp7,
  "short-chime-4": _temp8,
  "short-chime-5": _temp9
}