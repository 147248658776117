const _temp0 = require("./egbert_mad.png");
const _temp1 = require("./egbert_neutral.png");
const _temp2 = require("./egbert_smug.png");
const _temp3 = require("./jellychair_neutral.png");
const _temp4 = require("./jude_neutral.png");
const _temp5 = require("./lexis_neutral.png");
const _temp6 = require("./littimer_intense.png");
const _temp7 = require("./littimer_neutral.png");
const _temp8 = require("./littimer_sad.png");
const _temp9 = require("./pecksniff_doubt.png");
const _temp10 = require("./pecksniff_excited.png");
const _temp11 = require("./pecksniff_neutral.png");
const _temp12 = require("./soupwitch_cackle.png");
const _temp13 = require("./soupwitch_neutral.png");
const _temp14 = require("./soupwitch_sly.png");
const _temp15 = require("./sparks_neutral.png");
const _temp16 = require("./uriah_happy.png");
const _temp17 = require("./uriah_neutral.png");
const _temp18 = require("./uriah_satisfied.png");
module.exports = {
  "egbert_mad": _temp0,
  "egbert_neutral": _temp1,
  "egbert_smug": _temp2,
  "jellychair_neutral": _temp3,
  "jude_neutral": _temp4,
  "lexis_neutral": _temp5,
  "littimer_intense": _temp6,
  "littimer_neutral": _temp7,
  "littimer_sad": _temp8,
  "pecksniff_doubt": _temp9,
  "pecksniff_excited": _temp10,
  "pecksniff_neutral": _temp11,
  "soupwitch_cackle": _temp12,
  "soupwitch_neutral": _temp13,
  "soupwitch_sly": _temp14,
  "sparks_neutral": _temp15,
  "uriah_happy": _temp16,
  "uriah_neutral": _temp17,
  "uriah_satisfied": _temp18
}