const _temp0 = require("url:./bubbly.m4a");
const _temp1 = require("url:./coffin.m4a");
const _temp2 = require("url:./drons-lament.m4a");
const _temp3 = require("url:./foraging-no-sfx.m4a");
const _temp4 = require("url:./jellychair.m4a");
const _temp5 = require("url:./looking-frogward-to-the-end.m4a");
const _temp6 = require("url:./pecksniff.m4a");
const _temp7 = require("url:./rust-magic.m4a");
const _temp8 = require("url:./salvopriests.m4a");
const _temp9 = require("url:./uriah.m4a");
const _temp10 = require("url:./waterways.m4a");
const _temp11 = require("url:./waver.m4a");
const _temp12 = require("url:./weapons.m4a");
module.exports = {
  "bubbly": _temp0,
  "coffin": _temp1,
  "drons-lament": _temp2,
  "foraging-no-sfx": _temp3,
  "jellychair": _temp4,
  "looking-frogward-to-the-end": _temp5,
  "pecksniff": _temp6,
  "rust-magic": _temp7,
  "salvopriests": _temp8,
  "uriah": _temp9,
  "waterways": _temp10,
  "waver": _temp11,
  "weapons": _temp12
}