const _temp0 = require("./Bunting.png");
const _temp1 = require("./Cauldron.png");
const _temp2 = require("./Coffin.png");
const _temp3 = require("./Egbert_loincloth.png");
const _temp4 = require("./Egbert_naked.png");
const _temp5 = require("./Jellyfish_Chair.png");
const _temp6 = require("./Jude_1.png");
const _temp7 = require("./Lexus_1.png");
const _temp8 = require("./Littimer_jorts.png");
const _temp9 = require("./Littimer_naked.png");
const _temp10 = require("./Music_Dron.png");
const _temp11 = require("./Pecksniff_dry.png");
const _temp12 = require("./Pecksniff_possessed.png");
const _temp13 = require("./Pecksniff_slime.png");
const _temp14 = require("./Potted_Plant.png");
const _temp15 = require("./Pylon.png");
const _temp16 = require("./Uriah.png");
const _temp17 = require("./Weapons.png");
module.exports = {
  "Bunting": _temp0,
  "Cauldron": _temp1,
  "Coffin": _temp2,
  "Egbert_loincloth": _temp3,
  "Egbert_naked": _temp4,
  "Jellyfish_Chair": _temp5,
  "Jude_1": _temp6,
  "Lexus_1": _temp7,
  "Littimer_jorts": _temp8,
  "Littimer_naked": _temp9,
  "Music_Dron": _temp10,
  "Pecksniff_dry": _temp11,
  "Pecksniff_possessed": _temp12,
  "Pecksniff_slime": _temp13,
  "Potted_Plant": _temp14,
  "Pylon": _temp15,
  "Uriah": _temp16,
  "Weapons": _temp17
}