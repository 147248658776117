const _temp0 = require("./Background.png");
const _temp1 = require("./Building.png");
const _temp2 = require("./Center_Island.png");
const _temp3 = require("./Fire_Light.png");
const _temp4 = require("./Fireplace.png");
const _temp5 = require("./Foreground_Plants.png");
const _temp6 = require("./Left_Right_Islands.png");
module.exports = {
  "Background": _temp0,
  "Building": _temp1,
  "Center_Island": _temp2,
  "Fire_Light": _temp3,
  "Fireplace": _temp4,
  "Foreground_Plants": _temp5,
  "Left_Right_Islands": _temp6
}